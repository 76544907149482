import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_MEDIA_DATA = gql`
  query getMediaData($mediaId: ID!) {
    media(id: $mediaId) {
      id
      title
      duration
      selectedThumbCdnUrl
      thumbnailVttCdnUrl
      sourceKey
      isEncodingDone
      type
      duration
      playlistCdnUrl
      isPublished
      encodingVersion
      encodingProgress {
        id
        isEncodingDone
        encodingVersion
      }
      confidentiality {
        id
        stringValue
        disableMultiSession
      }
      subtitles {
        id
        languageCode
        key
      }
      discussion {
        id
        preset
      }
      publisher {
        id
      }
      live {
        id
        waitingThumbKey
        waitingThumbCdnUrl
        liveUrl
        hasReplay
        poll {
          id
        }
      }
      chapters {
        id
      }
      files {
        id
        key
        cdnUrl
        isActive
        type
      }
      publisher {
        id
        preferences {
          id
          pageSetting {
            id
            watermarkEnabled
            watermarkPosition
            watermarkLink
            watermarkImageKey
            watermarkImageCdnUrl
          }
          watermarkEnabled
          watermarkPosition
          watermarkLink
          watermarkImageKey
          watermarkImageCdnUrl
        }
      }
      pageSetting {
        id
        cssOverload
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogoKey
        pageLogoCdnUrl
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImageCdnUrl
        shouldShowSideBar
        restrictedDomains {
          id
          domain
        }
        description
      }
      embedSetting {
        id
        cssOverload
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        pageText
        pageTheme
        pageLogoKey
        pageLogoCdnUrl
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImageCdnUrl
        shouldShowSideBar
        restrictedDomains {
          id
          domain
        }
        privacyType
      }
      preRoll {
        id
        name
        skip
        skipDuration
        skipAfterXVideo
        rolls {
          id
          media {
            id
            playlistCdnUrl
            sourceCdnUrl
          }
        }
      }
      postRoll {
        id
        name
        skip
        skipDuration
        skipAfterXVideo
        rolls {
          id
          media {
            id
            playlistCdnUrl
            sourceCdnUrl
          }
        }
      }
    }
  }
`;
