import nookies, { setCookie, destroyCookie } from 'nookies';
import { destroyFormDataCookie } from './fieldsValues';

export const getToken = (ctx, contentId, contentType) => {
  const cookies = nookies.get(ctx);
  // if (
  //   contentId !== cookies.contentToShowId ||
  //   contentType !== cookies.contentToShowType
  // ) {
  //   destroyCookie(null, 'contentToken');
  //   destroyCookie(null, 'contentPassword');
  //   destroyCookie(null, 'contentToShowId');
  //   destroyCookie(null, 'contentToShowType');
  //   return null;
  // }
  return cookies?.contentToken;
};

export const getPassword = (ctx, contentId, contentType) => {
  const cookies = nookies.get(ctx);
  // if (
  //   contentId !== cookies.contentToShowId ||
  //   contentType !== cookies.contentToShowType
  // ) {
  //   destroyCookie(null, 'contentToken');
  //   destroyCookie(null, 'contentPassword');
  //   destroyCookie(null, 'contentToShowId');
  //   destroyCookie(null, 'contentToShowType');
  //   return null;
  // }
  // return cookies?.contentPassword;
};

export const Heading = ({heading, title}) => (
  <p>
    {heading}
    {' '} 
    {title && <span data-cy="confidentiality-title">{title}</span>}
  </p>
);

export const loadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = () => callback();
  document.body.appendChild(script);
};

export const setToken = (token) => {
  setCookie(null, 'contentToken', token, {
    maxAge: 3 * 60 * 60,
    sameSite: 'None',
    secure: true
  });
};

export const setPassword = (password) => {
  const hashPassword = Buffer.from(password || '').toString('base64');
  setCookie(null, 'contentPassword', hashPassword, {
    maxAge: 3 * 60 * 60,
    sameSite: 'None',
    secure: true
  });
};

export const getPrivacyType = ({ content }) => {
  const privacyType = content?.confidentiality?.stringValue || 'public';
  return privacyType;
};

export const getPrivacyId = ({ content }) => {
  const privacyId = content?.confidentiality?.id || null;
  return privacyId;
}

export const getPrivacyMultiSession = ({ content }) => {
  const privacyMultiSession = content?.confidentiality?.disableMultiSession || false;
  return privacyMultiSession;
}

export const handleLogOut = ({ router }) => {
  destroyFormDataCookie();
  destroyCookie({}, 'contentToken');
  destroyCookie({}, 'contentPassword');
  destroyCookie({}, 'contentToShowId');
  destroyCookie({}, 'contentToShowType');
  setTimeout(() => {
    router.reload();
  }, 1000);
};
