import { atom, useRecoilValue } from 'recoil';

export const brandSettingState = atom({
  key: 'brandSettingState',
  default: {
    accentColor: '#fff',
    pageTheme: '',
    pageLogoCdnUrl: '',
    pageTitle: '',
    pageText: '',
    shouldShowSummary: false,
    shouldShowShareButton: false,
    shouldShowSideBar: false,
    shouldShowPoll: false,
    shouldShowDiscussion: false,
    shouldShowTranscript: false,
    shouldShowChapter: false,
    shouldShowFile: false,
    watermarkEnabled: false,
    watermarkPosition: '',
    watermarkLink: '',
    watermarkImageCdnUrl: '',
    privacyType: '',
    description: {},
    cssOverload: '',
  }
});

export const GetBrandSettingFromRecoil = () => {
  const recoilBrandSetting = useRecoilValue(brandSettingState);
  const copyRecoilBrandSetting = { ...recoilBrandSetting };
  if (!copyRecoilBrandSetting.accentColor) {
    copyRecoilBrandSetting.accentColor = '#fff';
  }
  return copyRecoilBrandSetting;
};
