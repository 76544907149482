import nookies, { setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useUuid = (ctx) => {
  const [uuid, setUuid] = useState(null);

  useEffect(() => {
    let { uuid } = nookies.get(ctx);

    if (!uuid) {
      uuid = uuidv4();
      try {
        setCookie(ctx, 'uuid', uuid, {
          maxAge: 30 * 24 * 60 * 60,
          sameSite: 'None',
          secure: true
        });
      } catch (error) {
        console.log('error setting cookie', error);
      }
    }

    setUuid(uuid);
  }, []);

  return uuid;
};

export default useUuid;
