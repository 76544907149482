import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

// TODO-PLAYLIST Optimiser la query, elle est beaucoup trop lourde
export const GET_PLAYLIST = gql`
  query getPlaylist($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      isPublished
      hasUniqueChapterFeed
      rollOption
      pageSetting {
        id
        cssOverload
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        videoTitle
        pageText
        description
        pageTheme
        pageLogoCdnUrl
        accentColor
        shouldShowSideBar
        restrictedDomains {
          id
          domain
        }
      }
      embedSetting {
        id
        cssOverload
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        pageText
        pageTheme
        pageLogoCdnUrl
        accentColor
        shouldShowSideBar
        restrictedDomains {
          id
          domain
        }
        privacyType
      }
      content {
        id
        type
        language
        media {
          id
          title
          duration
          selectedThumbCdnUrl
          isEncodingDone
          type
          duration
          playlistCdnUrl
          thumbnailVttCdnUrl
          isPublished
          mediaInfos
          encodingVersion
          encodingProgress {
            id
            isEncodingDone
            encodingVersion
          }
          confidentiality {
            id
            stringValue
            disableMultiSession
          }
          subtitles {
            id
            languageCode
            cdnUrl
          }
          publisher {
            id
          }
          live {
            id
            waitingThumbCdnUrl
            liveUrl
            hasReplay
          }
          discussion {
            id
            preset
          }
          chapters {
            id
          }
          files {
            id
            cdnUrl
            isActive
            type
          }
          publisher {
            id
          }
          pageSetting {
            id
            videoTitle
            startsAt
            pageText
            description
          }
          embedSetting {
            id
            videoTitle
            startsAt
          }
          preRoll {
            id
            name
            skip
            skipDuration
            skipAfterXVideo
            rolls {
              id
              media {
                id
                playlistCdnUrl
                sourceCdnUrl
              }
            }
          }
          postRoll {
            id
            name
            skip
            skipDuration
            skipAfterXVideo
            rolls {
              id
              media {
                id
                playlistCdnUrl
                sourceCdnUrl
              }
            }
          }
        }
        live {
          id
          status
          title
          description
          liveUrl
          waitingThumbCdnUrl
          startDate
          shouldShowCountdown
          hasDVR
          hasReplay
          poll {
            id
            pollQuestions {
              id
            }
          }
          replay {
            id
            isEncodingDone
            type
            sourceCdnUrl
            playlistCdnUrl
            selectedThumbCdnUrl
            duration
            subtitles {
              id
              languageCode
              cdnUrl
            }
            encodingVersion
            encodingProgress {
              id
              isEncodingDone
              encodingVersion
            }
            publisher {
              id
            }
            live {
              id
              hasReplay
              liveUrl
              waitingThumbCdnUrl
            }
          }
          publisher {
            id
          }
          chapters {
            id
          }
          files {
            id
            cdnUrl
            isActive
            type
          }
          discussion {
            id
            preset
          }
          pageSetting {
            id
            videoTitle
            pageText
            description
          }
          embedSetting {
            id
            videoTitle
          }
        }
      }
    }
  }
`;
